.active {
    background-color: black;
}

.navbar {
    width: 100%;
    height: 100%;
    background-color: #ffb800;
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0;
    align-items: center;
    justify-content: space-between;
}

.navbar .leftside {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 150px;
}

.logo-circle {
    width: 100px; /* Adjust the width and height for your circular container */
    height: 100px;
    border-radius: 50%; /* Makes the container circular */
    overflow: hidden; /* Clips the content to the circular shape */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ffb800; /* Optional: Add border for better visibility */
  }
  
  .logo-img {
    width: 100%; /* Ensures the image fills the container */
    height: auto; /* Maintains aspect ratio */
    display: block;
    object-fit: cover; /* Fits the image inside the circle */
    border-radius: 50%; /* Ensures the image also appears circular */
  }
  
  

.navbar .rightside {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar a {
    color: white;
    text-decoration:none;
    background-color: none;
    font-size: x-large;
    font-weight: bold;
    margin: 35px;
    text-align: center;
    width: auto;
    padding: 0.5rem;
    margin: 0.5rem 0.5rem;
    border-radius: 0.5rem;
}

.navbar a :hover {
    background-color: #D20505;
}

.navbar .rightside button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
}

.navbar .rightside svg {
    font-size: 40px;
}

.navbar #open {
    padding-left: 0px;
  }
  .navbar #open img {
    display: none;
  }
  
  .navbar #close img {
    display: inherit;
  }
  
  .navbar #open .hiddenLinks {
    display: inherit;
    margin-left: 30px;
  }
  
  .navbar #close .hiddenLinks {
    display: none;
  }
  
  .navbar #open a {
    width: 70px;
    margin: 5px;
  }

@media only screen and (max-width: 600px){
    .navbar .rightside a {
        display: none;
    }

    .navbar .rightside {
        justify-content: flex-end;
        padding-right: 50px;
    }

    .navbar .rightside button {
        display: inherit;
    }
}

@media only screen and (min-width: 600px) {
    .navbar .rightside button {
        display: none;
    }

    .hiddenLinks {
        display: none;
    }
}