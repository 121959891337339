.footer {
  width: 100%;
  min-height: 200px;
  background-color: #121619;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
}

.socialMedia {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.socialMedia a {
  margin: 0 10px;
}

.socialMedia svg {
  color: white;
  font-size: 4vw; /* Adjusted size based on viewport width */
  cursor: pointer;
}

.footer p {
  color: white;
}

/* Media queries for responsiveness */
@media only screen and (max-width: 768px) {
  .footer {
    min-height: 150px; /* Adjusted height for smaller screens */
  }

  .socialMedia {
    margin-bottom: 10px;
  }

  .socialMedia svg {
    font-size: 6vw; /* Adjusted size for smaller screens */
  }
}
